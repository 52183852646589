import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
} from "@material-ui/core";
import { GET_IS_AFFILIATE_ID_ACTIVE, SAVE_CONTRACT_MONETARY_CAPACITY } from "../../common/models/contractMonetaryCapacity";
import { MergeContractMonetaryCapacityConfigInputType } from "../../types/graphql-global-types";
import { GetContractMonetaryCapacityConfig_LDPConfigQueryGroup_GetContractMonetaryCapacityConfig as ContractMonetaryCapacity } from "../../common/models/types/GetContractMonetaryCapacityConfig";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import SaveIcon from "@material-ui/icons/Save";
import { GetIsAffiliateIdActive } from "../../common/models/types/GetIsAffiliateIdActive";

interface ContractMonetaryCapacityFormModalProps {
  data: ContractMonetaryCapacity | any;
  action: string;
  close: Function;
  refetch: Function;
  existingAffiliateIds: (number | null)[];
}

export const ContractMonetaryCapacityFormModal = ({
  action,
  data,
  close,
  refetch,
  existingAffiliateIds,
}: ContractMonetaryCapacityFormModalProps) => {

  const [saveContractMonetaryCapacity] = useMutation(SAVE_CONTRACT_MONETARY_CAPACITY);
  const { register, handleSubmit, control, getValues, watch, errors, setValue, setError, clearErrors  } = useForm<MergeContractMonetaryCapacityConfigInputType>({});
  const classes = useStyles();
  const [
    getIsAffiliateIdActive,
    { data: isAffiliateIdActiveData, loading: isAffiliateIdActiveLoading },
  ] = useLazyQuery<GetIsAffiliateIdActive>(
    GET_IS_AFFILIATE_ID_ACTIVE,
    { fetchPolicy: "network-only" }
  );

  const [saveState, setSaveState] = useState<'preparing' | 'validating' | 'validated' | 'ready'>('preparing');

  const watchAffiliateId = watch('AffiliateId');

  const [disableButtons, setDisableButtons] = useState<boolean>(false);


  useEffect(()=>{
    if(saveState === 'validating' && !isAffiliateIdActiveLoading && isAffiliateIdActiveData){ //this step ensures that API AffiliateId validation is complete before setting field errors
      setSaveState('validated');
    } else if(saveState === 'validated' && watchAffiliateId && isAffiliateIdActiveData){
      if(isAffiliateIdActiveData.LDPConfigQueryGroup?.GetIsAffiliateIdActive){
        setSaveState('ready');
      } else {
        setSaveState('preparing');
        setError('AffiliateId', { type:'error', message: 'AffiliateId is inactive, please set a different value'});
        setDisableButtons(false);
      }
    } else if(saveState === 'ready' && watchAffiliateId){
      handleSaveContractMonetaryCapacity();
    }
  },[isAffiliateIdActiveLoading, saveState, isAffiliateIdActiveData, watchAffiliateId]);

  const handleSaveContractMonetaryCapacity = async () => {
    const formData = getValues();
    const mergeContractMonetaryCapacityConfigParams = {
      ...formData,
      AffiliateId: formData.AffiliateId || null,
      MaxMonthlyBudget:
        formData.MaxMonthlyBudget && formData.MaxMonthlyBudget >= 0
          ? Number(formData.MaxMonthlyBudget ?? 0)
          : null,
      MaxWeeklyBudget:
        formData.MaxWeeklyBudget && formData.MaxWeeklyBudget >= 0
          ? Number(formData.MaxWeeklyBudget ?? 0)
          : null,
      MaxDailyBudget:
        formData.MaxDailyBudget && formData.MaxDailyBudget >= 0
          ? Number(formData.MaxDailyBudget ?? 0)
          : null,

      UserId: '',
    }

    const result = await saveContractMonetaryCapacity({
      variables: {
        mergeContractMonetaryCapacityConfigParams
      }
    }).then((response) => {
      // evaluate responses here
      let allSuccess = response.data?.LDPConfigMutationGroup?.MergeContractMonetaryCapacityConfig;

      if(allSuccess){
        toast.success(`Contract Monetary Capacity ${(formData.ContractMonetaryCapacityConfigId > 0 ? "updated" : "created")} successfully.`);
        return true;
      } else {
        toast.error("Error saving contract monetary capacity configuration");
        return false;
      }
    }).catch(({errors})=>{                
      //toast.error("Unexpected error!");
      return false;
    });

    setDisableButtons(false);

    if(result){
      refetch();
      close();  
    }
  }

  const onSubmit = async (ContractMonetaryCapacityInput: MergeContractMonetaryCapacityConfigInputType) => {
    setDisableButtons(true);
    if(ContractMonetaryCapacityInput.AffiliateId){
      //check affiliateId if valid
      setSaveState('validating');
      getIsAffiliateIdActive({
        variables:{
          affiliateId: ContractMonetaryCapacityInput.AffiliateId
        }
      })
    } else {
      handleSaveContractMonetaryCapacity();
    }
    return false;
  };

  const ContractMonetaryCapacityConstraints = {    
    MaxMonthlyBudget: {
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Max Monthly Budget value."
      },
    },
    MaxWeeklyBudget: {
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Max Weekly Budget value."
      },
    },
    MaxDailyBudget: {
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Max Daily Budget value."
      },
      min: {
        value: 0,
        message: "Minimum value is 0"
      },
    },
    AffiliateId: {
      pattern: {
        value: /^[1-9]\d*$/s, 
        message: "Invalid Affiliate Id"
      },
      maxLength: {
        value: 5,
        message: "Invalid Affiliate Id"
      },
      validate: (value: string) => {
       if(value) return existingAffiliateIds.includes(parseInt(value)) ? `AffiliateId: ${value} is already in use` : true;
       else return existingAffiliateIds.includes(null) ? `AffiliateId: null is already in use` : true;
      }
    }
  };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs>
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractMonetaryCapacityConfigId"
              defaultValue={data?.ContractMonetaryCapacityConfigId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractId"
              defaultValue={data?.ContractId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractMonetaryCapacityConstraints.AffiliateId)}
              error={errors.AffiliateId && true}
              helperText={!isAffiliateIdActiveLoading && errors.AffiliateId && errors.AffiliateId?.message}
              name="AffiliateId"
              label="AffiliateId"
              defaultValue={data?.AffiliateId ?? ""}
              variant="outlined"
              type="number"
              disabled={(data.AffiliateId || saveState === 'validating') ? true : false}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractMonetaryCapacityConstraints.MaxMonthlyBudget)}
              error={errors.MaxMonthlyBudget && true}
              helperText={errors.MaxMonthlyBudget && errors.MaxMonthlyBudget?.message}
              name="MaxMonthlyBudget"
              label="Monthly budget ($)"
              defaultValue={data?.MaxMonthlyBudget ?? ""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractMonetaryCapacityConstraints.MaxWeeklyBudget)}
              error={errors.MaxWeeklyBudget && true}
              helperText={errors.MaxWeeklyBudget && errors.MaxWeeklyBudget?.message}
              name="MaxWeeklyBudget"
              label="Weekly budget ($)"
              defaultValue={data?.MaxWeeklyBudget ?? ""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractMonetaryCapacityConstraints.MaxDailyBudget)}
              error={errors.MaxDailyBudget && true}
              helperText={errors.MaxDailyBudget && errors.MaxDailyBudget?.message}
              name="MaxDailyBudget"
              label="Daily budget ($)"
              defaultValue={data?.MaxDailyBudget ?? ""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01"
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? false}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Notes"
              label="Notes"
              defaultValue={data?.Notes ?? ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper >
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
