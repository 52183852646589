import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ApolloQueryResult } from "apollo-client";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  DEL_CONTRACT_AFFILIATE_PRICE,
  GET_CONTRACT_AFFILIATE_PRICE,
  SAVE_CONTRACT_AFFILIATE_PRICE,
} from "../../common/models/contractAffiliatePrice";
import { GET_IS_AFFILIATE_ID_ACTIVE } from "../../common/models/contractMonetaryCapacity";
import {
  DelContractAffiliatePrice,
  DelContractAffiliatePriceVariables,
} from "../../common/models/types/DelContractAffiliatePrice";
import {
  GetContractAffiliatePrice,
  GetContractAffiliatePriceVariables,
} from "../../common/models/types/GetContractAffiliatePrice";
import {
  GetIsAffiliateIdActive,
  GetIsAffiliateIdActiveVariables,
} from "../../common/models/types/GetIsAffiliateIdActive";
import {
  SaveContractAffiliatePrice,
  SaveContractAffiliatePriceVariables,
} from "../../common/models/types/SaveContractAffiliatePrice";
import { getDuplicates } from "../../common/utils/array";
import { useConfirmation } from "../../components";
import { CollectiveAffiliatePriceData } from "./contractAffiliatePrice";

interface ContractAffiliatePriceData {
  AffiliateId: string;
  ContractAffiliatePriceId: any;
  ContractId: any;
  IsActive: boolean;
  Notes: string | null;
  Price: any;
}

interface ContractAffiliatePriceModalProps {
  data: CollectiveAffiliatePriceData;
  action: string;
  close: Function;
  refetch: (
    variables?: GetContractAffiliatePriceVariables | undefined
  ) => Promise<ApolloQueryResult<GetContractAffiliatePrice>>;
  contractId: number;
}

export const ContractAffiliatePriceFormModal = ({
  action,
  data,
  contractId,
  close,
  refetch,
}: ContractAffiliatePriceModalProps) => {
  const defaultValues: CollectiveAffiliatePriceData = {
    AffiliateId: "",
    IsActive: false,
    Notes: null,
    Price: 0.0,
    ModifiedDate: "",
    Affiliates: [],
  };

  const {
    data: existingPriceSetting,
    loading: priceLoading,
    refetch: priceRefetch,
  } = useQuery<GetContractAffiliatePrice, GetContractAffiliatePriceVariables>(
    GET_CONTRACT_AFFILIATE_PRICE,
    {
      fetchPolicy: "network-only",
      variables: { where: `ContractId = ${contractId}` },
    }
  );

  const [
    saveContractAffiliatePrice,
    { data: saveResult, error, loading },
  ] = useMutation<
    SaveContractAffiliatePrice,
    SaveContractAffiliatePriceVariables
  >(SAVE_CONTRACT_AFFILIATE_PRICE);
  const [
    delContractAffiliatePrice,
    { data: delResult, error: delError, loading: deleting },
  ] = useMutation<
    DelContractAffiliatePrice,
    DelContractAffiliatePriceVariables
  >(DEL_CONTRACT_AFFILIATE_PRICE);
  const [
    getIsAffiliateIdActive,
    {
      data: isAffiliateIdActiveData,
      error: isAffiliateIdActiveError,
      loading: isAffiliateIdActiveLoading,
    },
  ] = useLazyQuery<GetIsAffiliateIdActive, GetIsAffiliateIdActiveVariables>(
    GET_IS_AFFILIATE_ID_ACTIVE,
    { fetchPolicy: "network-only" }
  );

  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    watch,
  } = useForm<ContractAffiliatePriceData>({
    defaultValues: data || defaultValues,
  });

  const classes = useStyles();
  const pageTitle =
    (action == "create" ? "Add New" : "Edit") + " Contract Affiliate Price";

  const {
    Modal: Confirmation,
    closeModal: closeConfirmation,
    useModal: setConfirmation,
  } = useConfirmation();

  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [origAffiliateIds, setOrigAffiliateIds] = useState<number[]>([]);
  const [affiliateIds, setAffiliateIds] = useState<number[]>([]);
  const [delAffiliateIds, setDelAffiliateIds] = useState<number[]>([]);
  const [checkedAffiliateIds, setCheckedAffiliateIds] = useState<number[]>([]);
  const [checkAffiliateIds, setCheckAffiliateIds] = useState<number[]>([]);
  const [
    pendingSubmit,
    setPendingSubmit,
  ] = useState<ContractAffiliatePriceData>();

  const [conflicts, setConflicts] = useState<string[]>([]);

  useEffect(() => {
    const incoming = (data?.Affiliates || []).map((d) => d.AffiliateId).sort();

    setAffiliateIds(incoming);
    setOrigAffiliateIds(incoming);
    setDelAffiliateIds([]);
  }, [data]);

  useEffect(() => {
    if (checkAffiliateIds.length) {
      setDisableButtons(true);

      const id = Number(checkAffiliateIds[0]);

      if (isNaN(id) && id === 0) {
        setCheckAffiliateIds((prev) => {
          return prev.slice(1, prev.length);
        });
      } else {
        if (checkedAffiliateIds.includes(id)) {
          // already verified?
          setAffiliateIds((prev) => {
            /* if (prev.includes(id)) {
              setError("AffiliateId", { message: `Duplicate Affiliate ID: ${id}` });
            } */

            return [...prev, id];
          });

          setCheckAffiliateIds((prev) => {
            return prev.slice(1, prev.length);
          });
        } else {
          // consult API
          getIsAffiliateIdActive({
            variables: {
              affiliateId: checkAffiliateIds[0],
            },
          });
        }
      }
    } else {
      // check pending submit, submit if present and clear it
      pendingSubmit && onSubmit({ ...pendingSubmit });
      pendingSubmit && setPendingSubmit(undefined);

      Object.keys(errors).length === 0 && setDisableButtons(false);
    }
  }, [checkAffiliateIds]);

  useEffect(() => {
    if (isAffiliateIdActiveData) {
      const addId = Number(checkAffiliateIds[0]);

      if (
        !isAffiliateIdActiveData.LDPConfigQueryGroup?.GetIsAffiliateIdActive
      ) {
        setError("AffiliateId", {
          message: `${checkAffiliateIds[0]} is not an active Affiliate ID`,
        });

        // clear pending submit
        setPendingSubmit(undefined);
      } else {
        // clearErrors("AffiliateId");

        if (!checkedAffiliateIds.includes(addId)) {
          setCheckedAffiliateIds((prevChecked) => {
            return [...prevChecked, addId];
          });
        }
      }

      setCheckAffiliateIds((prev) => {
        return prev.slice(1, prev.length);
      });

      setAffiliateIds((prev) => {
        if (!prev.includes(addId)) {
          return [...prev, addId];
        } else {
          return prev;
        }
      });
    }
  }, [isAffiliateIdActiveData, isAffiliateIdActiveError]);

  useEffect(() => {
    // price conflict observer
    const price = Number(watch("Price"));
    const messages: string[] = [];
    for (const affiliateId of affiliateIds) {
      if (checkedAffiliateIds.includes(affiliateId)) {
        const currentSetting = existingPriceSetting?.LDPConfigQueryGroup?.ContractAffiliatePrice?.find(
          (cap) => cap?.AffiliateId === affiliateId
        );
        if (currentSetting && Number(currentSetting.Price) !== price) {
          messages.push(
            `Affiliate ID #${affiliateId} alredy exists on different price setting (${Number(
              currentSetting.Price
            ).toFixed(2)})`
          );
        }
      }
    }

    setConflicts(messages);

    // check duplicates
    const duplicates = getDuplicates(affiliateIds);
    if (duplicates.size) {
      setError("AffiliateId", {
        message: `Duplicate Affiliate ID(s): ${Array.from(duplicates).join(
          ", "
        )}`,
      });
    }
  }, [affiliateIds, checkedAffiliateIds]);

  const submitAction = async (
    AffiliatePriceFormInput: ContractAffiliatePriceData,
    targettedIds: (number | null)[]
  ) => {
    for (let i = 0; i < affiliateIds.length; i++) {
      await saveContractAffiliatePrice({
        variables: {
          affiliatePriceData: {
            ContractId: contractId,
            Notes: AffiliatePriceFormInput.Notes,
            Price:
              AffiliatePriceFormInput.Price &&
              AffiliatePriceFormInput.Price >= 0
                ? Number(AffiliatePriceFormInput.Price ?? 0)
                : null,
            ContractAffiliatePriceId: targettedIds[i],
            IsActive: true,
            AffiliateId: [affiliateIds[i]],
          },
          userId: "",
        },
      });
    }

    const forDeletionFiltered = delAffiliateIds.filter((delId) =>
      origAffiliateIds.includes(delId)
    );
    if (forDeletionFiltered.length) {
      await delContractAffiliatePrice({
        variables: {
          contractId: contractId,
          affiliateIDs: forDeletionFiltered,
          userId: "",
        },
      });

      setDelAffiliateIds([]);
    }

    toast.success(
      `Contract affiliate price${
        affiliateIds.length > 1 ? "s" : ""
      } successfully saved!`
    );
    refetch();
    close();
  };

  const onSubmit = async (
    AffiliatePriceFormInput: ContractAffiliatePriceData
  ) => {
    if (affiliateIds.length === 0) {
      setError("AffiliateId", { message: "Specify Affiliate ID to set." });
      return;
    }

    if (Object.keys(errors).length > 0) return;

    // check for unverified Affiliates IDs before proceeding
    const unverifiedList = affiliateIds.filter(
      (affId) => !checkedAffiliateIds.includes(affId)
    );
    if (unverifiedList.length) {
      setCheckAffiliateIds((prevIDs) => {
        if (prevIDs.length === 0) clearErrors("AffiliateId");
        const newUnverfiedList = [...prevIDs];
        unverifiedList.forEach((uId) => {
          if (!newUnverfiedList.includes(uId)) {
            newUnverfiedList.push(uId);
          }
        });

        return newUnverfiedList;
      });

      setPendingSubmit(AffiliatePriceFormInput);
      toast.info("Re-Validating Affiliate IDs...", { autoClose: 5000 });
      return;
    }

    if (checkAffiliateIds.length || disableButtons) {
      // not finished validating
      setPendingSubmit(AffiliatePriceFormInput);
      toast.info("Finishing Affiliate ID Validations...", { autoClose: 5000 });
      return;
    }

    setDisableButtons(true);

    const targettedIds: (number | null)[] = [];

    const updatedList = await priceRefetch();
    const diffOriginPriceIDs: number[] = [];

    if (
      !updatedList.errors &&
      updatedList.data.LDPConfigQueryGroup?.ContractAffiliatePrice
    ) {
      for (let i = 0; i < affiliateIds.length; i++) {
        const match = updatedList.data.LDPConfigQueryGroup?.ContractAffiliatePrice.find(
          (entry) => entry?.AffiliateId === affiliateIds[i]
        );
        if (match) {
          if (match.Price != AffiliatePriceFormInput.Price) {
            diffOriginPriceIDs.push(affiliateIds[i]);
          }

          targettedIds.push(match.ContractAffiliatePriceId);
        } else {
          targettedIds.push(null);
        }
      }
    }

    if (affiliateIds.length === targettedIds.length) {
      if (diffOriginPriceIDs.length) {
        setConfirmation(
          async () => {
            await submitAction(AffiliatePriceFormInput, targettedIds);
          },
          {
            title: `Affiliate(s) ${diffOriginPriceIDs.join(
              ", "
            )} already exists on different price setting. Update?`,
            description: "",
          }
        );
      } else {
        await submitAction(AffiliatePriceFormInput, targettedIds);
      }
    } else {
      toast.error(`Technical Error. Please contact admin!`);
    }

    setDisableButtons(false);
  };

  const AffiliatePriceConstraints = {
    AffiliateId: {
      validate: (value: any) => Array.isArray(value) && value.length > 0,
      message: "Affiliate ID is required.",
    },

    Price: {
      required: {
        value: true,
        message: "Price Floor is required.",
      },
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Price Floor value.",
      },
      min: {
        value: 0,
        message: "Minimum value is 0",
      },
      max: {
        value: 100,
        message: "Maximum value is 100",
      },
    },
  };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              inputRef={register(AffiliatePriceConstraints.Price)}
              error={errors.Price && true}
              helperText={errors.Price && errors.Price?.message}
              name="Price"
              label="Price"
              defaultValue={data?.Price ?? ""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              freeSolo
              clearOnBlur
              options={new Array<number>()}
              id="affiliate-id-list"
              value={affiliateIds || []}
              onChange={(event, newValue) => {
                // new value might contain new value and some deleted entries
                const newSet = newValue
                  .map((te) => Number(te))
                  .filter((n) => !isNaN(n));
                const toDelete = affiliateIds.filter(
                  (id) => !newSet.includes(id)
                );

                setAffiliateIds((prev) => {
                  const newAffiliateIds = newSet.filter(
                    (prevId) => !toDelete.includes(prevId)
                  );

                  const toCheck: number[] = [];
                  for (const checkId of newSet) {
                    const n = Number(`${checkId}`.trim());
                    if (!isNaN(n) && n !== 0) {
                      if (
                        !checkedAffiliateIds.includes(n) &&
                        !checkAffiliateIds.includes(n)
                      ) {
                        toCheck.push(n);
                      }
                    }
                  }

                  // for verification
                  if (toCheck.length) {
                    setCheckAffiliateIds((prev) => {
                      if (prev.length === 0) clearErrors("AffiliateId");

                      return [...prev, ...toCheck];
                    });
                  } else {
                    clearErrors("AffiliateId");
                    if (Object.keys(errors).length === 0)
                      setDisableButtons(false);
                  }

                  // for removal
                  setDelAffiliateIds((prevIds) => {
                    const newDelSet = prevIds.filter(
                      (delId) => !newAffiliateIds.includes(delId)
                    );
                    return [...newDelSet, ...toDelete];
                  });

                  // filter out un-verified IDs
                  return newAffiliateIds.filter(
                    (nId) => !toCheck.includes(nId)
                  );
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Affiliate IDs"
                  error={errors.AffiliateId && true}
                  helperText={errors.AffiliateId && errors.AffiliateId.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <List component="ul" aria-label="Affiliate Price Conflicts">
              {conflicts.map((c) => (
                <ListItem
                  component="li"
                  style={{ color: "#ff0000", fontSize: "0.8rem" }}
                >
                  {c}
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid item xs={12}>
            <TextField
              inputRef={register}
              name="Notes"
              label="Notes"
              defaultValue={data?.Notes ?? ""}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons || Object.keys(errors).length > 0}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={
                disableButtons || priceLoading || Object.keys(errors).length > 0
              }
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
      <Confirmation />
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
