import { useMutation } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { UPDATE_MULTI_CONTRACT_CAPACITY_PRICE } from "../../common/models/contractCapacity";
import { GetContractCapacity_LDPConfigQueryGroup_ContractCapacity as ContractCapacity } from "../../common/models/types/GetContractCapacity";
import { ProcMergeContractCapacityPriceInput } from "../../types/graphql-global-types";

interface ContractCapacityPriceFormModalProps {
  data: ContractCapacity | any;
  action: string;
  close: Function;
  refetch: Function;
}

export const ContractCapacityPriceFormModal = ({
  action,
  data,
  close,
  refetch,
}: ContractCapacityPriceFormModalProps) => {
  const [updateMultiContractCapacityPrice] = useMutation(
    UPDATE_MULTI_CONTRACT_CAPACITY_PRICE
  );
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setError,
  } = useForm<ProcMergeContractCapacityPriceInput>();
  const classes = useStyles();
  const pageTitle = "Multi-day Price Update";

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const [disableButtons, setDisableButtons] = useState<Boolean>(false);
  const onSubmit = (data: any) => {
    let ContractCapacityPriceInput: ProcMergeContractCapacityPriceInput = {
      ContractId: data.ContractId,
      Price: data.Price && data.Price >= 0 ? Number(data.Price ?? 0) : null,
      Delimiter: ",",
      DaysToUpdate: data.DaysToUpdate.join(),
      UserId: "",
    };

    updateMultiContractCapacityPrice({
      variables: {
        contractCapacityPriceInput: ContractCapacityPriceInput,
      },
    })
      .then((response: any) => {
        toast.success(
          `Contract Capacity Price ${
            ContractCapacityPriceInput?.ContractId ? "updated" : "created"
          } successfully.`
        );
        setDisableButtons(false);
        refetch();
        close();
      })
      .catch((error) => {
        console.log("updateMultiContractCapacityPrice", error);
      });
  };

  const ContractCapacityConstraints = {
    DaysToUpdate: {
      required: {
        value: true,
        message: "Schedule Day is required.",
      },
    },
    Price: {
      required: {
        value: true,
        message: "Price Floor is required.",
      },
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Price Floor value.",
      },
      min: {
        value: 0,
        message: "Minimum value is 0",
      },
      max: {
        value: 100,
        message: "Maximum value is 100",
      },
    },
  };

  const today = new Date();
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [selectedTokenType, setTokenType] = useState();

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs>
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractId"
              defaultValue={data?.ContractId ?? 0}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(ContractCapacityConstraints.Price)}
              error={errors.Price && true}
              helperText={errors.Price && errors.Price?.message}
              name="Price"
              label="Price Floor"
              defaultValue={data?.Price ?? ""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ onChange, ...props }) => (
                <Autocomplete
                  id="days-to-update-input"
                  multiple
                  filterSelectedOptions
                  disableCloseOnSelect
                  options={dayNames}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={errors.DaysToUpdate && true}
                      helperText={
                        errors.DaysToUpdate &&
                        ContractCapacityConstraints.DaysToUpdate.required
                          .message
                      }
                      label="Schedule Days"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              rules={{
                validate: (data) => {
                  // console.log(data)
                  if (data.length === 0) {
                    return false;
                  } else return true;
                },
              }}
              onChange={([event, data]) => {
                return data;
              }}
              name="DaysToUpdate"
              control={control}
              defaultValue={[]}
            ></Controller>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);
