import gql from "graphql-tag";

export const GET_LIVE_REPORTS = gql`
  query GetLiveReports {
    LDPConfigQueryGroup {
      LiveReport(orderby: "ReportName ASC") {
        LiveReportId
        ReportName
        StoredProcName
        DatabaseName
        InputVariable
      }
    }
  }
`;

export const RUN_LIVE_REPORTS = gql`
  query RunLiveReport(
    $StoredProcName: String!
    $DatabaseName: String!
    $FieldNames: [String]
    $FieldValues: [String]
    $FetchEDW: Boolean
  ) {
    LDPConfigQueryGroup {
      RunLiveReport(
        StoredProcName: $StoredProcName
        DatabaseName: $DatabaseName
        FieldNames: $FieldNames
        FieldValues: $FieldValues
        FetchEDW: $FetchEDW
      )
    }
  }
`;
